.loaderMain {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 1080px;
    background: #fff;
    z-index: 1;
    opacity: .5;
    justify-content: center;
    align-items: center;
}
.modal{
    width: 100%;
    height: 100%;
}