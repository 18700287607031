#modal-root{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    display: none;
}
iframe{
    border:0px;
    width: 100%;
    height: 100%;
}